<template>
  <div>
    <div class="w-100 d-flex justify-content-center mt-5">
      <button
        @click.prevent="registerAndReplyNow"
        class="btn main-btn submit-btn"
        :data-kt-indicator="registerLoading ? 'on' : 'off'"
        :disabled="registerLoading"
      >
        <span class="indicator-label">{{ $t('Register & Reply Now') }}</span>
        <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
    </div>

    <el-form
        :model="replyForm"
        ref="replyFormRef2"
        class="reply-form"
        :rules="replyFormDetailsRules"
    >
      <div class="reply-form__accordion">
        <div class="accordion-title">{{ $t('Quick Reply') }}</div>
        <div class="row custom-row">
          <div class="col-xl-6">
            <el-form-item prop="first_name" class="reply-form__input">
              <label>{{ $t('First Name') }}<span>*</span></label>
              <el-input v-model="replyForm.first_name"></el-input>
            </el-form-item>
          </div>
          <div class="col-xl-6">
            <el-form-item prop="last_name" class="reply-form__input">
              <label>{{ $t('Last Name') }}<span>*</span></label>
              <el-input v-model="replyForm.last_name"></el-input>
            </el-form-item>
          </div>
        </div>

        <template v-if="businessData.is_change_required">
          <el-form-item prop="company_name" class="reply-form__input">
            <label>{{ $t('Group Company Name') }}<span>*</span></label>
            <el-input v-model="replyForm.company_name"></el-input>
          </el-form-item>
          <el-form-item prop="country" class="reply-form__input">
            <label>{{ $t('Company Headquarters') }}<span>*</span></label>
            <el-select
                v-model="replyForm.country"
                placeholder=" "
                class="w-100"
                filterable
            >
              <el-option
                  v-for="(country, index) in countries"
                  :key="index"
                  :label="country"
                  :value="country"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="industries" class="reply-form__input">
            <label>{{ $t('Industry') }}<span>*</span></label>
            <el-select
                v-model="replyForm.industries"
                placeholder=" "
                class="w-100"
                filterable
            >
              <el-option
                  v-for="(globalRevenue, index) in industries"
                  :key="index"
                  :label="globalRevenue"
                  :value="globalRevenue"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="row custom-row">
            <div class="col-xl-6">
              <el-form-item prop="num_employees" class="reply-form__input">
                <label>{{ $t('Number of Employees') }}<span>*</span></label>
                <el-select
                    v-model="replyForm.num_employees"
                    placeholder=" "
                    class="w-100"
                    filterable
                >
                  <el-option
                      v-for="(employeesNumber, index) in employeesNumbers"
                      :key="index"
                      :label="employeesNumber"
                      :value="employeesNumber"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-xl-6">
              <el-form-item prop="revenue" class="reply-form__input">
                <label>{{ $t('Revenue') }}<span>*</span></label>
                <el-select
                    v-model="replyForm.revenue"
                    placeholder=" "
                    class="w-100"
                    filterable
                >
                  <el-option
                      v-for="(globalRevenue, index) in globalRevenues"
                      :key="index"
                      :label="globalRevenue.label"
                      :value="globalRevenue.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </template>

        <div class="d-flex justify-content-center">
          <button
            @click.prevent="handleReplyNow"
            class="btn main-btn submit-btn"
            :data-kt-indicator="loading ? 'on' : 'off'"
            :disabled="loading"
          >
            <span class="indicator-label">{{ $t('Reply Now') }}</span>
            <span class="indicator-progress">
              {{ $t("pleaseWait") }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>

        <div class="accept-terms-privacy-policy">
          {{ $t("By choosing 'reply now' you accept our") }}
          <span>
            <a :href="platformConfigs.platformLinks.Terms" target="_blank">{{ $t('Terms & Conditions') }}</a> &
            <a :href="platformConfigs.platformLinks.Privacy" target="_blank">{{ $t('Privacy Policy') }}</a>
          </span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import store from "@/store";
import {StaticDataEnum} from "@/store/enums/StaticDataEnum";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export default {
  name: "ReplyContentForm",
  emits: ['openReplyNowPopup', 'registerAndReplyNow'],
  props: {
    businessData: Object,
    userData: Object,
    queryDataId: String
  },
  data() {
    return {
      loading: false,
      registerLoading: false,
      collapseValue: '',
      replyForm: {
        first_name: '',
        last_name: '',
        company_name: '',
        country: '',
        industries: '',
        num_employees: '',
        revenue: '',
      },
      platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS)
    }
  },
  mounted() {
    store.dispatch('fetchStaticData', StaticDataEnum.EMPLOYEE_COUNTS);
    store.dispatch('fetchStaticData', StaticDataEnum.REVENUES);
    store.dispatch('fetchStaticData', StaticDataEnum.INDUSTRIES);
    store.dispatch('fetchStaticData', StaticDataEnum.COUNTRIES);
  },
  computed: {
    employeesNumbers() {
      return store.getters.employeesCounts;
    },
    globalRevenues() {
      return store.getters.globalRevenues;
    },
    industries() {
      return store.getters.allIndustries;
    },
    countries() {
      return store.getters.allCountries;
    },
    replyFormDetailsRules() {
      let data = {
        first_name: [
          { required: true, message: this.$t("Please input First name"), trigger: "change" }
        ],
        last_name: [
          { required: true, message: this.$t("Please input Last name"), trigger: "change" }
        ],
      }

      if (this.businessData.is_change_required) {
        data = {
          ...data,
          company_name: [
            { required: true, message: this.$t("Please input Group Company Name"), trigger: "change" }
          ],
          country: [
            { required: true, message: this.$t("Please input Company Headquarters"), trigger: "change" }
          ],
          industries: [
            { required: true, message: this.$t("Please input Industry"), trigger: "change" }
          ],
          num_employees: [
            { required: true, message: this.$t("Please input Number of Employees"), trigger: "change" }
          ],
          revenue: [
            { required: true, message: this.$t("Please input Revenue"), trigger: "change" }
          ],
        }
      }

      return data;
    }
  },
  methods: {
    handleReplyNow() {
      this.$refs.replyFormRef2.validate(valid => {
        if (valid) {
          this.loading = true;

          let payload = {
            data_id: this.queryDataId,
            first_name: this.replyForm.first_name,
            last_name: this.replyForm.last_name,
            business_id: this.businessData.business_id,
            is_business_updated: false,
          }

          if (this.businessData.is_change_required) {
            payload = {
              ...this.replyForm,
              industries: [this.replyForm.industries],
              data_id: this.queryDataId,
              business_id: this.businessData.business_id,
              is_business_updated: true,
            }
          }

          store.dispatch('updateNoAuthBusiness', payload).then(() => {
            this.$emit('openReplyNowPopup');
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
    registerAndReplyNow() {
      this.registerLoading = true;
      this.$emit('registerAndReplyNow');
    }
  }
}
</script>
