<template>
  <div class="reply-without-account">
    <div class="reply-content">
      <div class="reply-content__header">
        <div class="reply-content__header--title">
          <h1>{{ $t('Provide feedback') }}</h1>
          <p>{{ $t('Reply now or register to get all benefits of CXFacts platform') }}</p>
        </div>
        <div class="reply-content__header--login">
          <span>{{ $t('Already a user?') }}</span>
          <router-link to="/sign-in">{{ $t('Login') }}</router-link>
        </div>
      </div>

      <div class="reply-content__card">
        <div class="reply-content__card--left">
          <div class="reply-content__card--left--logo">
            <img :src="platformConfigs.platformLogos.blueLogo" alt="">
          </div>

          <div class="reply-content-form">
            <div class="reply-content-form__top">
              <div class="reply-content-form__header">
                <div class="session-id">{{ $t('Session') }} <span>#{{ surveyData.id }}</span></div>
                <div class="bank-info">
                  <div class="d-flex align-items-center mb-5"  v-if="surveyData.banks_data && surveyData.banks_data.length === 1">
                    <div class="bank-info__icon">
                      <img :src="surveyData.banks_data[0].icon_path" alt="">
                    </div>
                    <div class="bank-info__name">{{ surveyData.banks_data[0].name }}</div>
                  </div>
                  <div class="bank-label mb-5" v-else-if="surveyData.banks_data && surveyData.banks_data.length">
                    <div class="bank-label__title">{{ $t('Banks Included') }}:</div>
                    <div class="bank-label__cards">
                      <div class="bank-label__cards--item" v-for="(bank, index) in surveyData.banks_data" :key="index">
                        <img :src="bank.icon_path" alt="">
                        {{ bank.name }}
                      </div>
                    </div>
                  </div>
                  <div class="bank-label mb-5">
                    <div class="bank-label__title">{{ $t('Product Areas Included') }}:</div>
                    <div class="bank-label__cards">
                      <div class="bank-label__cards--item" v-for="productArea in surveyData.product_areas" :key="productArea">{{ productArea }}</div>
                    </div>
                  </div>
                  <div class="bank-label mb-3">
                    <div class="bank-label__title">{{ $t('Countries Included') }}:</div>
                    <div class="bank-label__cards">
                      <div class="bank-label__cards--item" v-for="country in surveyData.countries" :key="country">{{ country }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <slot name="form"></slot>

            </div>

            <div class="reply-content-form__footer">
              <div class="reply-content-form__footer--title">
                {{ $t('Get in touch with CXFacts') }}
              </div>

              <div class="d-flex flex-column flex-xl-row gap-4 w-100">
                <a class="btn btn-outline main-btn-outline" :href="BookPhoneInterview_Link" target="_blank">
                  <img src="/media/buying/icons/call-icon.svg" alt="">
                  {{ $t('Book Phone Interview') }}
                </a>
                <a class="btn btn-outline main-btn-outline" :href="BookIntroMeeting_Link" target="_blank" >
                  <img src="/media/buying/icons/videocam-outline.svg" alt="">
                  {{ $t('Book Intro Meeting') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="reply-content__card--right">
          <div class="reply-content__card--right--logo">
            <img src="/media/logos/logo-blue-white.svg" alt="">
          </div>
          <div class="track-section">
            <div class="track-section__title">
              {{ $t('Track the Service Performance of your Banks for Free') }}
            </div>
            <div class="track-section__text">
              {{ $t('Generate Insights that lead to Actions and Results') }}
            </div>

            <div class="track-section__cards">
              <div class="card-item" v-for="(item, index) in services" :key="index">
                <div class="card-item__icon">
                  <img :src="item.icon" alt="">
                </div>
                <div class="card-item__text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>

          <template v-if="showBestBankSection">
            <div class="track-section__subtitle">
              {{ $t('Best Bank Management System') }}
            </div>

            <div class="track-section__bg-image">
              <img src="/media/buying/best-bank-management-system.png" alt="">
            </div>
          </template>

          <div class="need-help">
            <span>{{ $t('Need help?') }}</span>
            <a :href="platformConfigs.platformLinks.Contactus" target="_blank">{{ $t('Contact Us') }}</a>
          </div>

          <button
            class="meeting-with-us-button"
            @click="registerAndReplyNow"
            :data-kt-indicator="registerLoading ? 'on' : 'off'"
            :disabled="registerLoading"
          >
            <span class="indicator-label">{{ $t('Sign Up Today!') }}</span>
            <span class="indicator-progress">
              {{ $t("pleaseWait") }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </div>

      <div class="reply-content__link">
        <img src="/media/buying/icons/ph_globe-simple.svg" alt="">
        <a :href="platformConfigs.platformDomain" target="_blank">{{ platformConfigs.platformDomain }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ReplyContentForm from "@/buying-teams/pages/business/feedbacks/reply-without-account/ReplyContentForm";
import { BookIntroMeeting_Link, BookPhoneInterview_Link } from "@/core/config/constants";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";

export default {
  name: "ReplyWithoutAccount",
  props: {
    showBestBankSection: {
      default: true,
      type: Boolean
    },
    banksData: Object,
    surveyData: Object
  },
  data() {
    return {
      registerLoading: false,
      BookPhoneInterview_Link,
      BookIntroMeeting_Link,
        platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS)
    }
  },
  components: {
    ReplyContentForm
  },
  computed: {
    services() {
      return [
        {
          icon: '/media/buying/icons/reply-edit.svg',
          text: this.$t('Get free access to CXFacts platform. Keep and analyze data on your banks.')
        },
        {
          icon: '/media/buying/icons/reply-diagnostic.svg',
          text: this.$t('Track performance across all banks, countries and products.')
        },
        {
          icon: '/media/buying/icons/reply-people.svg',
          text: this.$t('Create and share relevant feedback with colleagues and banks. Prepare for bank meetings.')
        },
        {
          icon: '/media/buying/icons/reply-bar-chart.svg',
          text: this.$t('See market benchmark on banks globally. Compare to your own banks.')
        }
      ]
    }
  },
  methods: {
    registerAndReplyNow() {
      this.registerLoading = true;
      this.$emit('registerAndReplyNow');
    }
  }
}
</script>

<style lang="scss">
.reply-without-account {
  .main-btn-outline, .main-btn-outline:hover {
    border: 1px solid #435BF4;
  }
  .btn {
    transition: .3s ease-in-out;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.main-btn, &.main-btn:hover {
      background: linear-gradient(236.8deg, #6A92F6 3.06%, #435BF4 69.67%) !important;
    }
    img {
      margin-right: 10px;
    }
    &:hover {
      opacity: .7;
    }
  }
  .reply-content {
    max-width: 1800px;
    margin: 0 auto;
    &__header {
      margin: 30px 42px 36px 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--title {
        h1 {
          font-weight: 500;
          font-size: 32px;
          line-height: 109%;
          letter-spacing: -0.05em;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 12px;
        }
        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #9CA0AB;
          margin-bottom: 0;
        }
      }
      &--login {
        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #9CA0AB;
          margin-right: 12px;
        }
        a {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #435BF4;
        }
      }
    }
    &__card {
      background: #FFFFFF;
      box-shadow: 0 6px 29px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      margin: 0 54px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      margin-bottom: 22px;
      &--left,
      &--right {
        width: 50%;
      }
      &--left {
        padding: 77px 63px 60px 38px;
        &--logo {
          display: none;
        }
      }

      &--right {
        padding: 87px 32px 50px 32px;
        background: #435BF4;
        position: relative;

        & > * {
          position: relative;
          z-index: 2;
        }

        &--logo {
          position: absolute;
          top: 26px;
          left: -109px;
        }

        &::before {
          content: "";
          width: 190px;
          height: 190px;
          background-image: url("/media/buying/icons/circle-1.svg");
          position: absolute;
          top: 137px;
          left: -29px;
        }

        &::after {
          content: "";
          width: 349px;
          height: 361px;
          background-image: url("/media/buying/icons/circle-2.svg");
          position: absolute;
          top: 512px;
          right: -54px;
        }

        .track-section {
          width: 100%;
          &__title {
            font-family: 'Quantico', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 39px;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            text-align: center;
          }
          &__text {
            font-family: 'Quantico', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 39px;
            text-align: center;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            opacity: 0.5;
          }
          &__cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 10px;
            gap: 10px;
            .card-item {
              background: rgba(0, 0, 0, 0.3);
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
              backdrop-filter: blur(2px);
              border-radius: 16px;
              padding: 16px 16px 36px 16px;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 16px;
                border: 1px solid transparent;
                background: linear-gradient(134.13deg, #FFFFFF -17.61%, rgba(255, 255, 255, 0) 34.92%, #FFFFFF 130.72%) border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
                mask-composite: exclude;
              }
              > * {
                position: relative;
                z-index: 2;
              }
              &__icon {
                height: 166px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 158.02%;
                text-align: center;
                color: #FFFFFF;
              }
            }
          }
          &__subtitle {
            font-family: 'Quantico', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 39px;
            text-align: center;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            margin-top: 33px;
          }
          &__bg-image {
            width: 100%;
            position: relative;
            img {
              width: 100%;
            }
            &::after {
              content: "";
              width: 180px;
              height: 180px;
              background-image: url("/media/buying/icons/circle-2.svg");
              background-size: 100%;
              position: absolute;
              bottom: -10px;
              left: 86px;
              z-index: -1;
            }
          }
        }

        .logo-white {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 35px;
          margin-bottom: 19px;
        }

        .meeting-with-us-button {
          background: #FFFFFF;
          box-shadow: 0 11px 52px rgba(8, 35, 204, 0.45);
          border-radius: 42px;
          font-weight: 700;
          font-size: 16px;
          line-height: 158.02%;
          color: #435BF4;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          width: max-content;
          padding: 10px 60px;
          margin-top: 19px;
          min-width: 364px;
          cursor: pointer;
          border: none;
          transition: .3s ease-in-out;
          img {
            margin-right: 11px;
          }
          &:not(:disabled):hover {
            background: rgb(212 218 255);
          }
          &:disabled {
            cursor: default;
          }
        }

        .need-help {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 27px;
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            opacity: 0.6;
          }
          a {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: flex-end;
            text-decoration-line: underline;
            color: #FFFFFF;
            margin-left: 8px;
          }
        }
      }
    }
    &__link {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 57px;
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #ABABAB;
        margin-left: 4px;
      }
    }

    @media (max-width: 1300px) {
      &__header {
        margin: 20px 22px 26px 22px;
        &--title {
          h1 {
            font-size: 28px;
            margin-bottom: 8px;
          }
          p {
            font-size: 16px;
          }
        }
        &--login {
          span {
            font-size: 16px;
            margin-right: 4px;
          }
          a {
            font-size: 16px;
          }
        }
      }
      &__card {
        margin-left: 22px;
        margin-right: 22px;
        &--left {
          padding: 77px 32px 60px 24px;
        }
      }
    }

    @media (max-width: 1200px) {
      &__card {
        &--right {
          .track-section {
            &__cards {
              .card-item {
                &__icon {
                  height: 115px;
                  img {
                    width: 60px;
                  }
                }
                &__text {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      &__header {
        flex-direction: column;
        align-items: flex-start;
        &--title {
          h1 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
        &--login {
          margin-top: 8px;
        }
      }
      &__card {
        flex-direction: column;
        &--right, &--left {
          width: 100%;
        }
        &--left {
          position: relative;
          padding-right: 24px;
          &--logo {
            display: block;
            position: absolute;
            top: 26px;
            left: 50%;
            transform: translateX(-50%);
            img {
              width: 194px;
            }
          }
        }
        &--right {
          padding-top: 32px;
          padding-right: 24px;
          padding-left: 24px;
          &--logo {
            display: none;
          }
        }
      }
    }

    @media (max-width: 600px) {
      &__card {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
        &--right {
          .meeting-with-us-button {
            width: max-content;
            padding-left: 20px;
            padding-right: 20px;
          }
          .track-section__cards {
            grid-template-columns: repeat(1, 1fr);

            .card-item {
              &__icon {
                height: 84px;
              }
            }
          }
        }
      }
    }
  }
  .reply-content-form {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .submit-btn {
      min-width: 281px;
    }
    &__header {
      //padding-bottom: 20px;

      .session-id {
        font-weight: 500;
        font-size: 28px;
        line-height: 39px;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.4);
        span {
          color: #000000;
        }
      }
      .bank-info {
        width: 100%;
        padding: 10px;
        margin-top: 7px;
        background: #F7F6F6;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        &__icon {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          margin-right: 10px;
          background: #fff;
          overflow: hidden;
          border: 1px solid #e2e2e2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        &__name {
          font-weight: 600;
          font-size: 18px;
          line-height: 158.02%;
          color: #2B2B2B;
        }
        .bank-label {
          &__title {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #696969;
            margin-bottom: 8px;
          }
          &__cards {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            &--item {
              background: #FFFFFF;
              border-radius: 5px;
              padding: 8px 7px;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
              display: flex;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      &--title {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #ABABAB;
        margin-bottom: 15px;
      }
      .btn {
        min-width: 288px;
        width: 100%;
      }
    }

    .reply-form {
      margin-top: 26px;
      .el-form-item {
        margin-bottom: 26px;
      }
      .custom-row {
        margin-left: -16px;
        margin-right: -16px;
        & > * {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .el-form-item__content {
        line-height: revert;
      }
      &__input {
        label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #434343;
          opacity: 0.6;
          margin-bottom: 13px;
          span {
            color: #E22D21;
          }
        }
        .el-input__inner {
          height: 56px;
          width: 100%;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          background: #FFFFFF;
          border: 1px solid rgba(67, 91, 244, 0.2);
          box-shadow: inset 0px 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
          border-radius: 5.28234px;
          &:focus {
            border-color: #435BF4;
          }
        }
        .el-input__suffix {
          .el-input__validateIcon {
            display: none;
          }
        }
        &.is-error {
          .el-input__inner {
            border-color: #ff938c;
          }
        }
        .last-updated {
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #666666;
          margin-top: 8px;
          span {
            font-weight: 700;
            color: #000000;
          }
        }
      }

      &__accordion {
        margin-right: -63px;
        padding-right: 63px;
        margin-left: -38px;
        padding-left: 38px;
        padding-top: 16px;
        padding-bottom: 21px;
        background: rgba(206, 212, 248, 0.13);
        .accordion-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 158.02%;
          margin-bottom: 18px;
          color: #435BF4;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 1300px) {
    .reply-content-form {
      &__header {
        .session-id {
          font-size: 24px;
        }
        .bank-info {
          padding: 8px;
          &__icon {
            width: 40px;
            height: 40px;
          }
          &__name {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .reply-content-form {
      &__footer {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 600px) {
    .reply-content-form .submit-btn {
      min-width: 100%;
    }
    .reply-content-form__footer {
      align-items: flex-start;
      .btn {
        min-width: 100%;
      }
    }
  }
}

.accept-terms-privacy-policy {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #ABABAB;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  a {
    color: #ABABAB;
    text-decoration: underline;
  }
}
</style>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');
</style>
